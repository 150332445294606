import { useEffect, useState, useCallback } from "react";
import {
  TopToolbar,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  usePermissions,
  BooleanField,
  FilterForm,
  DateTimeInput,
  FilterButton,
  FunctionField,
} from "react-admin";
import { CSVLink } from "react-csv";
import axios from "axios";
import config from "../config";
import { getItemWithExpiry } from "../utils/sessionData";
import { Stack } from "rsuite";
import UserNameHyperlink from "../components/UserNameHyperlink";

const TaxDeductions = (props: any) => {
  const { permissions } = usePermissions();
  const [apiData, setApiData] = useState([]);
  const [totalTdsAmountDeducted, setTotalTdsAmountDeducted] = useState(0);
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
  });

  const handleFilterChange = (field: any, value: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const transactionFilters = [
    <DateTimeInput
      source="startDate"
      alwaysOn
      label="Start Date"
      onChange={(e) => handleFilterChange("startDate", e.target.value)}
    />,
    <DateTimeInput
      source="endDate"
      alwaysOn
      label="End Date"
      onChange={(e) => handleFilterChange("endDate", e.target.value)}
    />,
  ];

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      {/* <SelectColumnsButton />
      {permissions.includes("admin") && (
        <CSVLink
          data={apiData}
          headers={getHeaders(apiData[0])}
          separator=","
          enclosingCharacter='"'
          filename={"tax_deductions.csv"}
        >
          Export to CSV
        </CSVLink>
      )} */}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput placeholder="Search UserName" source="userName" alwaysOn />
      <SearchInput
        placeholder="Search PanCard"
        source="panCard"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>TDS History</h2>
      <div
        style={{
          fontWeight: "bold",
          color: "black",
          marginTop: "5px",
          paddingLeft: "0%",
          whiteSpace: "pre",
        }}
      ></div>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <FunctionField
            label="Pan Card Number"
            render={(record: any) => {
              if (record.kyc?.data?.cardType === "pan") {
                return record.kyc?.data?.cardNumber || "-";
              } else {
                return "-";
              }
            }}
          />
          <FunctionField
            label="Full Name"
            render={(record: any) => {
              if (
                record.kyc?.data?.cardType === "pan" &&
                record.kyc?.data?.cardNumber
              ) {
                return record.name;
              } else {
                return "-";
              }
            }}
          />
          <TextField
            source="taxdeduction.financialYear"
            label="Financial Year"
          />
          <FunctionField
            source="taxdeduction.totalDepositAmount"
            label="Total Deposit Amount"
            render={(record: any) =>
              `₹${record.taxdeduction.totalDepositAmount}`
            }
          />
          <FunctionField
            source="taxdeduction.totalWithdrawalAmount"
            label="Total withdrawal amount"
            render={(record: any) =>
              `₹${record.taxdeduction.totalWithdrawalAmount}`
            }
          />
          <FunctionField
            source="amount"
            label="Amount"
            render={(record: any) => `₹${record.amount}`}
          />{" "}
          <DateField
            source="createdAt"
            showTime
            label="Transaction created at"
          />
          <DateField
            source="updatedAt"
            showTime
            label="Transaction updated at"
          />
          <FunctionField
            source="taxdeduction.withdrawalAmountAfterTaxDeduction"
            label="Withdrawal amount after tax deduction"
            render={(record: any) =>
              `₹${record.taxdeduction.withdrawalAmountAfterTaxDeduction}`
            }
          />
          <FunctionField
            source="taxdeduction.totalTdsAmountDeducted"
            label="Total TDS Amount Deducted"
            render={(record: any) =>
              `₹${record.taxdeduction.totalTdsAmountDeducted}`
            }
          />{" "}
          <FunctionField
            source="taxdeduction.totalTdsAmountDeducted"
            label="Total TDS Amount Deducted"
            render={(record: any) =>
              `₹${record.taxdeduction.totalTdsAmountDeducted}`
            }
          />
          <FunctionField
            source="taxdeduction.netWithdrawalAmount"
            label="Net withdrawal amount"
            render={(record: any) =>
              `₹${record.taxdeduction.netWithdrawalAmount}`
            }
          />
          <BooleanField
            source="taxdeduction.isTdsDeducted"
            label="Is TDS Deducted?"
          />
          <DateField
            source="taxdeduction.transactionFrom"
            showTime
            label="Transaction from"
          />
          <DateField
            source="taxdeduction.transactionTo"
            showTime
            label="Transaction to"
          />
          <DateField
            source="createdAt"
            showTime
            label="Transaction created at"
          />
          <DateField
            source="updatedAt"
            showTime
            label="Transaction updated at"
          />
        </DatagridConfigurable>
      </List>
    </>
  );
};

const getHeaders = (item: any) => {
  if (item) {
    return Object.keys(item);
  }
  return [];
};

export default TaxDeductions;
