import React, { useState, useEffect } from "react";
import { Stack } from "rsuite";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { callRestApi } from "../../../utils/callRestApi";

interface GameHistoryData {
  _id: string;
  userId: string;
  tableId: string;
  joinFee: string;
  endAmount: string;
  roundNo: number;
  winLoseAmount: number;
  outcome: "won" | "lost";
  createdAt: string;
  updatedAt: string;
  userName: string;
  cashoutValue: number | null;
  meta: {
    count: {
      total: number;
    };
  };
}

interface TopUserData {
  _id: string;
  roundNo: number;
  crashValue: number;
  serverSeed: string;
  players: {
    userId: string;
    username: string;
    avatar: number;
    playerSeed: string;
    _id: string;
  }[];
  createdAt: string;
}

const AviatorGameHistory: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [gameHistoryData, setGameHistoryData] = useState<GameHistoryData[]>([]);
  const [roundDetails, setRoundDetails] = useState<GameHistoryData[]>([]);
  const [topUserData, setTopUserData] = useState<TopUserData[]>([]);
  const [selectedRound, setSelectedRound] = useState<number | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [detailsPage, setDetailsPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [searchUsername, setSearchUsername] = useState<string>("");
  const [searchRoundNo, setSearchRoundNo] = useState<string>("");
  const detailsPerPage = 10;

  const UserNameHyperlink: React.FC<{
    label: string;
    source: string;
    to: string;
  }> = ({ label, source, to }) => {
    return (
      <Link
        to={`/users/${to}`}
        style={{
          color: "#00408B",
          textDecoration: "none",
        }}
      >
        {label}
      </Link>
    );
  };

  const getUniqueRounds = (data: GameHistoryData[]) => {
    const uniqueRoundMap = new Map();
    data.forEach((game) => {
      if (!uniqueRoundMap.has(game.roundNo)) {
        uniqueRoundMap.set(game.roundNo, game);
      }
    });
    return Array.from(uniqueRoundMap.values());
  };

  const fetchGameHistory = async () => {
    setLoading(true);
    try {
      let filter = {};
      if (searchRoundNo) {
        filter = { roundNo: parseInt(searchRoundNo) };
      }
      const apiUrl = `/api/aviator-game-history?count=${rowsPerPage}&filter=${encodeURIComponent(
        JSON.stringify(filter)
      )}&key=&skip=${
        (currentPage - 1) * rowsPerPage
      }&sortBy=createdAt&sortDir=-1`;
      const response = await callRestApi(apiUrl, "GET");
      if (response?.status === 200) {
        setGameHistoryData(response.data.items);
        const total = response.data.items[0]?.meta.count.total || 0;
        setTotalPages(Math.ceil(total / rowsPerPage));
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoundDetails = async (roundNo: number) => {
    setLoading(true);
    try {
      const filter = encodeURIComponent(JSON.stringify({ roundNo }));
      const detailsUrl = `/api/aviator-game-history?count=100&filter=${filter}&key=&skip=0&sortBy=createdAt&sortDir=-1`;
      const topUsersUrl = `/api/crash-history?count=100&filter=${filter}&key=ttYXm2U4V1Qwah5893ewp78494XP19IZMl&skip=0&sortBy=createdAt&sortDir=-1`;

      const [detailsResponse, topUsersResponse] = await Promise.all([
        callRestApi(detailsUrl, "GET"),
        callRestApi(topUsersUrl, "GET"),
      ]);

      if (detailsResponse?.status === 200) {
        setRoundDetails(detailsResponse.data.items);
      }
      if (topUsersResponse?.status === 200) {
        setTopUserData(topUsersResponse.data.items);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGameHistory();
  }, [currentPage, rowsPerPage, searchRoundNo]);

  const handleDetailsClick = (roundNo: number) => {
    if (selectedRound === roundNo) {
      setSelectedRound(null);
      setRoundDetails([]);
      setTopUserData([]);
    } else {
      setSelectedRound(roundNo);
      setDetailsPage(1);
      fetchRoundDetails(roundNo);
    }
  };

  const filteredRounds = getUniqueRounds(gameHistoryData).filter((game) =>
    searchRoundNo ? game.roundNo.toString().includes(searchRoundNo) : true
  );

  const filteredRoundDetails = roundDetails.filter(
    (game) =>
      !searchUsername ||
      game.userName.toLowerCase().includes(searchUsername.toLowerCase())
  );

  const paginatedRoundDetails = filteredRoundDetails.slice(
    (detailsPage - 1) * detailsPerPage,
    detailsPage * detailsPerPage
  );

  return (
    <div style={{ padding: "20px" }}>
      {/* First Box - Aviator Round History */}
      <Paper
        sx={{
          width: "100%",
          mb: 4,
          minHeight: "400px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#00408B",
            padding: "15px 20px",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
          }}
        >
          <Typography variant="h6" sx={{ color: "white" }}>
            Aviator Round History
          </Typography>
        </Box>

        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <input
              type="text"
              placeholder="Search Round No"
              value={searchRoundNo}
              onChange={(e) => setSearchRoundNo(e.target.value)}
              style={{
                padding: "8px",
                border: "1px solid #ddd",
                borderRadius: "4px",
                width: "150px",
              }}
            />
            <select
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(Number(e.target.value))}
              style={{
                padding: "8px",
                border: "1px solid #ddd",
                borderRadius: "4px",
                width: "120px",
              }}
            >
              <option value={5}>5 rounds</option>
              <option value={10}>10 rounds</option>
              <option value={20}>20 rounds</option>
            </select>
          </Box>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Round No</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <CircularProgress size={24} />
                  </TableCell>
                </TableRow>
              ) : (
                filteredRounds.map((game) => (
                  <TableRow key={game._id}>
                    <TableCell>{game.roundNo}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        onClick={() => handleDetailsClick(game.roundNo)}
                        sx={{ backgroundColor: "#00408B" }}
                      >
                        {selectedRound === game.roundNo ? "Hide" : "Details"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>

          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(_, page) => setCurrentPage(page)}
              color="primary"
            />
          </Box>
        </Box>
      </Paper>

      {/* Second Box - Round Details */}
      {selectedRound && (
        <Paper
          sx={{
            width: "100%",
            mb: 4,
            minHeight: "400px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#00408B",
              padding: "15px 20px",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" sx={{ color: "white" }}>
              Round Details #{selectedRound}
            </Typography>
            <input
              type="text"
              placeholder="Search username"
              value={searchUsername}
              onChange={(e) => setSearchUsername(e.target.value)}
              style={{
                padding: "8px",
                border: "1px solid #ddd",
                borderRadius: "4px",
                width: "200px",
              }}
            />
          </Box>

          <Box sx={{ p: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Table ID</TableCell>
                  <TableCell>Join Fee</TableCell>
                  <TableCell>Win/Loss Amount</TableCell>
                  <TableCell>Outcome</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRoundDetails.map((game) => (
                  <TableRow key={game._id}>
                    <TableCell>
                      <div style={{ marginBottom: "8px", marginTop: "8px" }}>
                        <Link
                          to={`/api/users/${game.userId}/show`}
                          style={{
                            color: "#00408B",
                            marginLeft: "4px",
                            textDecoration: "none",
                          }}
                        >
                          {game.userName}
                        </Link>
                      </div>
                    </TableCell>

                    <TableCell>{game.tableId}</TableCell>
                    <TableCell>₹{game.joinFee}</TableCell>
                    <TableCell>₹{game.winLoseAmount}</TableCell>
                    <TableCell>
                      <img
                        src={
                          game.outcome === "won"
                            ? "/images/won.png"
                            : "/images/loss.png"
                        }
                        alt={game.outcome}
                        style={{ width: "24px", height: "24px" }}
                      />
                    </TableCell>
                    <TableCell>
                      {new Date(game.createdAt).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Pagination
                count={Math.ceil(filteredRoundDetails.length / detailsPerPage)}
                page={detailsPage}
                onChange={(_, page) => setDetailsPage(page)}
                color="primary"
              />
            </Box>
          </Box>
        </Paper>
      )}

      {/* Third Box - Top Users Data */}
      {selectedRound && topUserData.length > 0 && (
        <Paper
          sx={{
            width: "100%",
            minHeight: "400px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#00408B",
              padding: "15px 20px",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
            }}
          >
            <Typography variant="h6" sx={{ color: "white" }}>
              Top Users Data
            </Typography>
          </Box>

          <Box sx={{ p: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Server Seed</TableCell>
                  <TableCell>Crash Value</TableCell>
                  <TableCell>Players</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topUserData.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell sx={{ fontFamily: "monospace" }}>
                      {item.serverSeed}
                    </TableCell>
                    <TableCell>{item.crashValue}x</TableCell>
                    <TableCell>
                      <Stack
                        direction="column"
                        spacing={2}
                        alignItems="flex-start"
                      >
                        {item.players.map((player) => (
                          <Box
                            key={player._id}
                            style={{
                              marginBottom: "8px",
                              marginTop: "8px",
                              textAlign: "left",
                            }}
                          >
                            <Link
                              to={`/api/users/${player.userId}/show`}
                              style={{
                                color: "#00408B",
                                marginLeft: "4px",
                                textDecoration: "none",
                              }}
                            >
                              {player.username}
                            </Link>
                          </Box>
                        ))}
                      </Stack>
                    </TableCell>

                    <TableCell>
                      {new Date(item.createdAt).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      )}
    </div>
  );
};

export default AviatorGameHistory;
