import {
  List,
  TextField,
  DateField,
  ChipField,
  DatagridConfigurable,
  Filter,
  SearchInput,
  SelectArrayInput,
  FilterForm,
  usePermissions,
  SelectColumnsButton,
  FilterButton,
  TopToolbar,
  ExportButton,
  DateTimeInput,
  FunctionField,
} from "react-admin";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";

import UserNameHyperlink from "../../components/UserNameHyperlink";
const transactionFilters = [
  <DateTimeInput source="startDate" alwaysOn />,
  <DateTimeInput source="endDate" alwaysOn />,
];

const ListToolbar = () => (
  <Stack direction="row">
    <FilterForm filters={transactionFilters} />
  </Stack>
);

const Coupontransactions = (props: any) => {
  const { permissions } = usePermissions();
  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
    </TopToolbar>
  );
  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Amount"
        source="amount"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}> Coupon History</h2>

      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="orderId" label="Order Id" />
          <DateField source="createdAt" showTime label="Created At" />
          <FunctionField
            source="amount"
            label="Amount"
            render={(record: any) => `₹${record.amount}`}
          />{" "}
          <FunctionField
            source="breakDown.main"
            label="Main Amount"
            render={(record: any) => `₹${record.breakDown.main}`}
          />
          <FunctionField
            source="breakDown.win"
            label="Win Amount"
            render={(record: any) => `₹${record.breakDown.win}`}
          />
          <FunctionField
            source="breakDown.bonus"
            label="Bonus Amount"
            render={(record: any) => `₹${record.breakDown.bonus}`}
          />
          <DateField source="createdAt" showTime label="Created At" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Coupontransactions;
