import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  usePermissions,
  FunctionField,
  DateTimeInput,
} from "react-admin";
import { Stack, Typography } from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";

const Admintransactions = (props: object) => {
  const { permissions } = usePermissions();
  const transactionFilters = [
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Order Id"
        source="orderId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Amount"
        source="amount"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Table ID"
        source="tableId"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Admin Transactions</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="orderId" label="Order ID" />
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <UserNameHyperlink
            label="Refund By"
            source="refundBy"
            to="refundBy"
          />
          <FunctionField
            source="amount"
            label="Amount"
            render={(record: any) => `₹${record.amount}`}
          />{" "}
          <TextField source="game" />
          <TextField source="tableId" label="Table ID" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <FunctionField
            source="breakDown.main"
            label="Main Amount"
            render={(record: any) => `₹${record.breakDown.main}`}
          />
          <FunctionField
            source="breakDown.win"
            label="Win Amount"
            render={(record: any) => `₹${record.breakDown.win}`}
          />
          <FunctionField
            source="breakDown.bonus"
            label="Bonus Amount"
            render={(record: any) => `₹${record.breakDown.bonus}`}
          />{" "}
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Admintransactions;
